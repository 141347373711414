<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  mounted() {
    const appLoading: any = document.getElementById("loading-bg");
    if (appLoading) appLoading.style.display = "none";
  }
}
</script>

<style lang="scss" type="text/scss">
@import "styles/main";
@import "styles/global";

.theme--light.v-btn:hover {
  cursor: pointer;
}
</style>
