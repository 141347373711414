import { NumberFormats } from "vue-i18n";
export const numberFormats: NumberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  ja: {
    currency: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
    },
  },
};
